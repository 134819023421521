<script setup>
import MediaWithPopup from '@/Components/MediaWithPopup.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    location: Object,
    liked: Boolean,
    hidden: Boolean,
});
</script>

<template>
    <div class="aspect-h-1 aspect-w-1 h-0 w-full overflow-hidden rounded bg-white">
        <MediaWithPopup
            v-if="location.profile_media"
            :media="location.profile_media"
            classes="object-cover w-full h-full"
        />
        <div
            :class="{
                'opacity-0': !hidden,
            }"
            class="
                pointer-events-none
                z-10
                flex
                items-center
                justify-center
                bg-red/60
                text-4
                font-bold
                uppercase
                text-white
            "
        >
            {{ t('Blocked') }}
        </div>
    </div>
    <h3 class="font-bold">
        {{ location.display_name }}
    </h3>
    <slot />
</template>
